<template>
  <div>
    <likewize-scanner
        v-if="isLikewizeScanner"
        :width="isModal ? 125 : width"
        :height="isModal ? 125 : height"
        :ratio="checkIfMobile ? 1 : isModal ? 2.7 : ratio"
        @barcodeHasBeenScanned="barcodeScanned"
    ></likewize-scanner>
    <barcode-picker
        v-else
        @barcodeHasBeenScanned="barcodeScanned"
        ref="barcodeScanner"
    ></barcode-picker>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'scanner',

  props: {
    scanner: {
      type: String,
      default: 'auto'
    },
    isModal: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    ratio: {
      type: Number,
      default: 2.3
    }
  },

  computed: {
    ...mapGetters(['appRegion', 'user']),

    isLikewizeScanner() {
        if (this.scanner === 'likewize') {
            return true;
        }
        if (this.scanner === 'auto') {
            if (this.appRegion !== 'UK') {
                return true;
            }
            const validUKUsers = [455, 301, 1116, 1769, 1899, 2135, 2178];
            if (validUKUsers.includes(this.user.id)) {
                return true;
            }
        }
        return false;
    },

    checkIfMobile() {
      return window.innerWidth <= 576;
    }
  },

  methods: {
    barcodeScanned(data) {
      this.$emit('barcodeHasBeenScanned', data);
    },
  }
};
</script>
